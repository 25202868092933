import { Header, HeaderLogoWrap, Logo } from '@gmini/ui-kit'
import { getModulesLinkFromEnv } from '@gmini/common'
import { goals } from '@gmini/common/lib/metrika'
import { useNavbar } from '@gmini/common/lib/components'

import { useEffect } from 'react'

import { envLinks } from '../../config'
import { authClient } from '../../keycloak'
import { useAppDispatch, useAppSelector } from '../../store/store'

import { getCurrentUser } from '../../store/users/actions'

import { BrandContainer, BrandTitle, HeaderContainer } from './AppHeader.styled'

export const AppHeader = () => {
  const dispatch = useAppDispatch()
  const userInfo = useAppSelector(state => state.currentUser)
  const { NavbarDropDown } = useNavbar({
    navModules: getModulesLinkFromEnv(envLinks),
  })

  useEffect(() => {
    dispatch(getCurrentUser())
  }, [dispatch])

  return (
    <HeaderContainer>
      <Header
        onLogout={() => authClient.logout()}
        brand={
          <BrandContainer>
            <HeaderLogoWrap>
              <Logo />
            </HeaderLogoWrap>
            <BrandTitle>Administration</BrandTitle>
            {NavbarDropDown}
          </BrandContainer>
        }
        userInfo={userInfo}
        onProfileClick={() => goals.showUserMenu()}
      />
    </HeaderContainer>
  )
}
