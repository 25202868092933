import { CheckMark, Select } from '@gmini/ui-kit'
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react'

import { declOfNum } from '../../helpers'

import { Scope } from '../../store/roles/types'
import { useAppSelector } from '../../store/store'

import {
  Option,
  PermissionSettingWrapper,
  SelectWrapper,
} from './PermissionSetting.styled'

type PermissionSettingProps = {
  disabled: boolean
  resource: {
    id: number
    name: string
    curScopes: Scope[]
    initScopes: Scope[]
  }
  options: Scope[]
  handleUpdateRole: (value: Scope[]) => void
  setChangesMap: Dispatch<SetStateAction<{ [x: string]: number }>>
}

const getValueLabel = (length: number, name: string) => {
  if (length > 1) {
    return `${length} ${declOfNum(length, [
      'значение',
      'значения',
      'значений',
    ])}`
  } else if (length < 1) {
    return 'Запрещено'
  }
  return name
}

export const PermissionSetting = ({
  disabled,
  resource,
  handleUpdateRole,
  options,
  setChangesMap,
}: PermissionSettingProps) => {
  const { name, curScopes, initScopes } = resource

  const { pending } = useAppSelector(state => state.roles)

  const optionsMap = useMemo(
    () =>
      options.reduce(
        (acc: { [x: string]: Scope }, o) => ({ ...acc, [o.scopeId]: o }),
        {},
      ),
    [options],
  )

  const handleChange = useCallback(
    (scopes: Scope[], curScopes: Scope[]) => {
      const initScopes = options.map(o =>
        scopes.map(s => s.scopeId).includes(o.scopeId),
      )
      const currScopes = options.map(o =>
        curScopes.map(s => s.scopeId).includes(o.scopeId),
      )

      setChangesMap(map => ({
        ...map,
        [name]: initScopes.reduce(
          (acc, scope, i) => (currScopes[i] === scope ? acc : acc + 1),
          0,
        ),
      }))
      handleUpdateRole(curScopes)
    },
    [handleUpdateRole, name, setChangesMap, options],
  )

  return (
    <PermissionSettingWrapper>
      <span>{name}</span>
      <SelectWrapper forbidden={!curScopes.length}>
        <Select
          allowDelete={false}
          renderSelectValue={() =>
            getValueLabel(
              curScopes.length,
              optionsMap[curScopes[0]?.scopeId]?.name,
            )
          }
          renderOption={({
            idx,
            option,
            active,
            hover,
            onMouseEnterOption,
          }) => (
            <Option
              key={idx}
              hover={hover}
              active={active}
              onClick={() => {
                const newState = curScopes.some(
                  s => s.scopeId === option.scopeId,
                )
                  ? curScopes.filter(s => s.scopeId !== option.scopeId)
                  : [...curScopes, option]
                handleChange(initScopes, newState)
              }}
              onMouseEnter={() => onMouseEnterOption(idx)}
            >
              {option.name}
              {curScopes.some(s => s.scopeId === option.scopeId) ? (
                <CheckMark />
              ) : null}
            </Option>
          )}
          options={options}
          emptyOptionListMessage='Нет доступных совпадений'
          value={null}
          getOptionLabel={(option: Scope) => option.name}
          disabled={pending || disabled}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onChange={val => {}}
        />
      </SelectWrapper>
    </PermissionSettingWrapper>
  )
}
