export enum modulesNames {
  CHECKUP_MANAGEMENT = 'Проверки',
  SET_MANAGEMENT = 'Set-management',
  ESTIMATION_MANAGEMENT = 'Объёмы',
  FIELD_INSPECTION_MANAGEMENT = 'Стройконтроль',
  CHECKLIST_MANAGEMENT = 'Чек-листы',
  ISSUE_MANAGEMENT = 'Замечания',
  GSTATION = 'G-station',
  GRAND_TENDER = 'Гранд-тендеры',
  TENDER = 'Тендеры',
  ADMIN_CONSOLE = 'Administration',
  LK = 'Личный кабинет',
}

export type ModuleUsersCount = {
  module: keyof typeof modulesNames
  count: number
}
