import { useEffect, useState } from 'react'

import { useParams } from 'react-router'

import { isNotEmpty } from '@gmini/utils'

import { useAppDispatch } from '../../store/store'

import { User } from '../../store/users/types'

import { addUserRoles, deleteUserRoles } from '../../store/users/actions'

import { SelectMultipleRoles } from '../SelectMultipleRoles/SelectMultipleRoles'

import {
  ActionButton,
  ActionButtons,
  UserRolesWrapper,
} from './UserRoles.styled'

type UserRolesProps = {
  user: User
  getUser: (id: string) => Promise<void>
}

export const UserRoles = ({ user, getUser }: UserRolesProps) => {
  const { userRoles: roles, id, name } = user
  const { projectUrn } = useParams<{
    projectUrn: string
  }>()
  const initRoles = roles.find(r => r.projectUrn === projectUrn)?.roles || []
  const initRoleIds = initRoles.map(r => r.id)
  const [roleIds, setRoleIds] = useState<(number | null)[]>(initRoleIds)
  const dispatch = useAppDispatch()
  const filteredRoleIds = roleIds.filter(isNotEmpty)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setRoleIds(initRoleIds), [user])

  const handleSubmit = async () => {
    const roleIdsToAdd = filteredRoleIds.filter(id => !initRoleIds.includes(id))
    const roleIdsToDelete = initRoles
      .filter(({ id }) => !filteredRoleIds.includes(id))
      .map(({ id }) => id)
    const promises = []
    if (roleIdsToDelete.length) {
      promises.push(
        dispatch(
          deleteUserRoles({
            id,
            roleIds: roleIdsToDelete,
            projectUrn,
            name,
            toastrText: 'Изменены роли пользователя',
          }),
        ),
      )
    }
    if (roleIdsToAdd.length) {
      promises.unshift(
        dispatch(
          addUserRoles({
            id,
            roleIds: roleIdsToAdd,
            projectUrn,
            name,
            toastrText: promises.length ? '' : 'Изменены роли пользователя',
          }),
        ),
      )
    }
    await Promise.all(promises)
    if (promises.length) {
      getUser(id)
    }
  }
  return (
    <UserRolesWrapper>
      <SelectMultipleRoles
        roleIds={roleIds}
        setRoleIds={setRoleIds}
        initialRoleIds={initRoleIds}
      />
      <ActionButtons>
        <ActionButton onClick={() => setRoleIds(initRoleIds)} color='secondary'>
          Отменить
        </ActionButton>
        <ActionButton
          color='primary'
          disabled={!filteredRoleIds.length}
          onClick={handleSubmit}
        >
          Применить
        </ActionButton>
      </ActionButtons>
    </UserRolesWrapper>
  )
}
