import styled, { css } from 'styled-components'

export const RoleTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.132923px;
  color: #353b60;
  margin-bottom: 5px;
`

export const RoleDescription = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.15px;
  color: #353b60;
  opacity: 0.7;
`

export const RoleModules = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
  margin-top: 7px;

  & div {
    background: #f4f4f8;
    color: rgba(53, 59, 96, 0.6);
  }
`

const selectedStyles = css`
  background: #f1f3ff;
  cursor: pointer;
  path {
    fill: #4c5ecf;
  }
  ${RoleTitle}, ${RoleDescription} {
    color: #2235aa;
  }
  ${RoleModules} div {
    background: #e4e7f8;
    color: #4c5ecf;
  }
  &::after {
    content: none;
  }
  & + ::after {
    content: none;
  }
`

type RolesListItemWrapperProps = {
  selected: boolean
}

export const RolesListItemWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  padding-left: 16px;
  border-radius: 4px;

  & svg {
    flex-shrink: 0;
  }

  &::after {
    content: '';
    height: 1px;
    background: #e5e7f1;
    border-radius: 4px;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
  }

  &:first-child::after {
    content: none;
  }

  ${(props: RolesListItemWrapperProps) =>
    props.selected ? selectedStyles : ''}

  &:hover {
    ${selectedStyles}
  }
`

export const RoleInfoBlock = styled.div``
