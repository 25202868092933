import { HTMLAttributes, useMemo } from 'react'

import { Role } from '../../store/roles/types'
import { UserType } from '../../store/users/types'
import { RolesListArrowIcon } from '../icons/RolesListArrowIcon'
import { RoleBadge } from '../RoleBadge/RoleBadge'

import {
  RoleDescription,
  RoleInfoBlock,
  RolesListItemWrapper,
  RoleTitle,
  RoleModules,
} from './RolesListItem.styled'

type RoleListItemProps = {
  hubRole: Role
  selected: boolean
} & HTMLAttributes<HTMLDivElement>

export const RolesListItem = ({
  hubRole,
  selected,
  onClick,
}: RoleListItemProps) => {
  const { title, description, resources } = hubRole

  const modulesInRole = useMemo(
    () =>
      Object.values(resources || {}).filter(
        v => Object.values(v.resources).flatMap(r => r.scopes).length,
      ),
    [resources],
  )
  return (
    <RolesListItemWrapper onClick={onClick} selected={selected}>
      <RoleInfoBlock>
        <RoleTitle>{title}</RoleTitle>
        {description && <RoleDescription>{description}</RoleDescription>}
        {modulesInRole.length ? (
          <RoleModules>
            {modulesInRole.map(m => (
              <RoleBadge key={m.id} roleId={UserType.NEW} roleTitle={m.name} />
            ))}
          </RoleModules>
        ) : null}
      </RoleInfoBlock>
      <RolesListArrowIcon />
    </RolesListItemWrapper>
  )
}
