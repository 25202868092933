import { Pointer } from '@gmini/ui-kit'
import { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'

import { useAppDispatch, useAppSelector } from '../../store/store'
import { getProjectUsers } from '../../store/users/actions'
import { User } from '../../store/users/types'
import { AddUserPopup } from '../AddUserPopup/AddUserPopup'
import { EditUserDrawer } from '../EditUserPage/EditUserDrawer'
import { AddUserIcon } from '../icons/AddUserIcon'
import { UsersIcon } from '../icons/UsersIcon'
import { UserList } from '../UserList/UserList'

import {
  AddUserButton,
  BackButton,
  ButtonContainer,
  ListHeading,
  Title,
  TitleCount,
  UserListWrapper,
} from './ProjectPage.styled'

export const ProjectPage = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { list } = useAppSelector(state => state.projects)
  const [userDetails, setUserDetails] = useState<User | null>(null)
  const [addUserPopupOpen, setAddUserPopupOpen] = useState(false)
  const { projectUrn, hubId } = useParams<{
    projectUrn: string
    hubId: string
  }>()
  const {
    list: users,
    total,
    pending,
  } = useAppSelector(state => state.projectUsers)

  const projectName = list.find(p => p.urn === projectUrn)?.name

  const getUser = useCallback(
    async (id: string) => {
      const users = await dispatch(getProjectUsers({ projectUrn })).unwrap()
      const user = users.find(user => user.id === id)
      setUserDetails(user || null)
    },
    [dispatch, setUserDetails, projectUrn],
  )

  useEffect(() => {
    dispatch(getProjectUsers({ projectUrn }))
  }, [dispatch, projectUrn])

  return (
    <UserListWrapper>
      <ListHeading>
        <Title>
          <BackButton onClick={() => history.push(`/hub/${hubId}`)}>
            <Pointer />
          </BackButton>
          <UsersIcon />
          Пользователи проекта {projectName} <TitleCount>{total}</TitleCount>
        </Title>
        <ButtonContainer>
          <AddUserButton
            onClick={() => setAddUserPopupOpen(true)}
            size='regular'
            color='primary'
            leftIcon={<AddUserIcon width='16px' height='16px' />}
          >
            <span>Добавить пользователя</span>
          </AddUserButton>
        </ButtonContainer>
      </ListHeading>
      <AddUserPopup
        open={addUserPopupOpen}
        onClose={() => setAddUserPopupOpen(false)}
      />
      <EditUserDrawer
        userState={userDetails}
        setUserState={setUserDetails}
        getUser={getUser}
      />
      <UserList
        localStoragePrefix='project'
        users={users}
        pending={pending}
        setUserDetails={setUserDetails}
      />
    </UserListWrapper>
  )
}
