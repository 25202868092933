import styled from 'styled-components'

export const PermissionSettingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;

  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.132923px;
    color: #353b60;
    opacity: 0.9;
  }
`

type SelectWrapperProps = {
  forbidden: boolean
}

export const SelectWrapper = styled.div`
  max-width: 200px;
  color: ${({ forbidden }: SelectWrapperProps) =>
    forbidden ? 'rgba(0, 3, 53, 0.4)' : 'rgba(0, 3, 53, 1)'};
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.1px;
  background: #fff;
  div div {
    background-color: inherit;
  }
  div div div {
    color: currentColor;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    background-color: inherit;
  }

  input {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.1px;
    background: #fff;
  }
`

type OptionProps = {
  hover?: boolean
  disabled?: boolean
  active?: boolean
}

export const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  ${(props: OptionProps) =>
    props.hover && 'background: rgba(53, 59, 96, 0.05);'}
  ${(props: OptionProps) =>
    props.active && 'background: rgba(92, 130, 229, 0.15);'}
  ${(props: OptionProps) =>
    props.active &&
    props.hover &&
    'background: linear-gradient(rgba(53, 59, 96, 0.05), rgba(53, 59, 96, 0.05)), linear-gradient(rgba(92, 130, 229, 0.15), rgba(92, 130, 229, 0.15));'}
  padding: 9.5px 12px;
  color: #353b60;
  cursor: ${(props: OptionProps) => (props.disabled ? 'default' : 'pointer')};
  user-select: none;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 14px;

  & svg {
    flex-shrink: 0;
  }

  &:first-child {
    border-radius: 4px 4px 0 0;
  }

  &:last-child {
    border-radius: 0 0 4px 4px;
  }
`
