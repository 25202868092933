import { Button, ScrollBar } from '@gmini/ui-kit'
import styled from 'styled-components'

export const RoleListWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
`

export const RolePageWrap = styled.div`
  display: flex;
  width: 100%;
`

export const ButtonContainer = styled.div`
  padding: 0 24px 24px;
`

export const AddButton = styled(Button)`
  background: #4c5ecf;
  color: #fff;
  & path {
    fill: #fff;
  }
  &:hover,
  &:active {
    background: #2e387c;
  }
`

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #353b60;
  padding: 32px 24px;
`
export const TitleCount = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: rgba(53, 59, 96, 0.5);
`
export const ListHeading = styled.div``

export const RolesList = styled.div`
  max-width: 650px;
`

export const ScrollbarStyled = styled(ScrollBar)`
  padding-left: 24px;
  padding-right: 12px;
`
