import { IconButton, Pointer, Tooltip } from '@gmini/ui-kit'
import { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'

import { getCompanyById } from '../../store/companies/actions'

import { useAppDispatch, useAppSelector } from '../../store/store'
import { getCompanyUsers } from '../../store/users/actions'
import { User } from '../../store/users/types'
import { AddOrEditCompanyPopup } from '../AddOrEditCompanyPopup/AddOrEditCompanyPopup'
import { AddUserPopup } from '../AddUserPopup/AddUserPopup'
import { CompanyLogoPlaceholder } from '../CompanyLogoPlaceholder/CompanyLogoPlaceholder'
import { EditUserDrawer } from '../EditUserPage/EditUserDrawer'
import { AddUserIcon } from '../icons/AddUserIcon'
import { EditTransparentIcon } from '../icons/EditTransparentIcon'
import { UsersIcon } from '../icons/UsersIcon'
import { UserList } from '../UserList/UserList'

import {
  AddUserButton,
  BackButton,
  BackButtonWrapper,
  ButtonContainer,
  //TODO: на момент появления описания
  // Description,
  ListHeading,
  Name,
  NameAndCount,
  Title,
  TitleCount,
  TitleInfo,
  TitleTop,
  UserListWrapper,
} from './CompanyPage.styled'

export const CompanyPage = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { list } = useAppSelector(state => state.companies)
  const [userDetails, setUserDetails] = useState<User | null>(null)
  const [addUserPopupOpen, setAddUserPopupOpen] = useState(false)
  const [editCompanyPopupOpen, setEditCompanyPopupOpen] = useState(false)
  const { companyId, hubId } = useParams<{
    companyId: string
    hubId: string
  }>()
  const {
    list: users,
    total,
    pending,
  } = useAppSelector(state => state.companyUsers)

  const [company, setCompany] = useState(
    list.find(c => c.id.toString() === companyId),
  )

  const getUser = useCallback(
    async (id: string) => {
      const users = await dispatch(getCompanyUsers({ companyId })).unwrap()
      const user = users.find(user => user.id === id)
      setUserDetails(user || null)
    },
    [dispatch, setUserDetails, companyId],
  )

  useEffect(() => {
    if (!company) {
      dispatch(getCompanyById({ companyId }))
        .unwrap()
        .then(c => setCompany(c))
    }
    dispatch(getCompanyUsers({ companyId }))
  }, [dispatch, companyId, company])

  if (!company) {
    return null
  }

  return (
    <UserListWrapper>
      <ListHeading>
        <Title>
          <TitleTop>
            <BackButtonWrapper
              onClick={() => history.push(`/hub/${hubId}/companies`)}
            >
              <BackButton>
                <Pointer />
              </BackButton>
              Назад
            </BackButtonWrapper>
            <Tooltip title='Редактировать компанию'>
              <IconButton
                onClick={() => setEditCompanyPopupOpen(true)}
                type='circle'
              >
                <EditTransparentIcon />
              </IconButton>
            </Tooltip>
          </TitleTop>
          <TitleInfo>
            <CompanyLogoPlaceholder photo={company?.photo || ''} />
            <NameAndCount>
              <Name>{company?.companyName}</Name>
              <TitleCount>
                <UsersIcon />
                {total}
              </TitleCount>
            </NameAndCount>
            {/* TODO: на момент появления описания */}
            {/* {company?.description ? <Description>
              {company.description}
            </Description> : null} */}
          </TitleInfo>
        </Title>
        <ButtonContainer>
          <AddUserButton
            onClick={() => setAddUserPopupOpen(true)}
            size='regular'
            color='primary'
            leftIcon={<AddUserIcon width='16px' height='16px' />}
            disabled
          >
            <span>Добавить пользователя</span>
          </AddUserButton>
        </ButtonContainer>
      </ListHeading>
      <AddUserPopup
        open={addUserPopupOpen}
        onClose={() => setAddUserPopupOpen(false)}
      />
      <AddOrEditCompanyPopup
        open={editCompanyPopupOpen}
        onClose={() => setEditCompanyPopupOpen(false)}
        mode='edit'
        company={company}
        setCompany={setCompany}
      />
      <EditUserDrawer
        userState={userDetails}
        setUserState={setUserDetails}
        getUser={getUser}
      />
      <UserList
        localStoragePrefix='company'
        users={users}
        pending={pending}
        setUserDetails={setUserDetails}
        noExtendedEditOrDelete
        excludeCompanyField
      />
    </UserListWrapper>
  )
}
