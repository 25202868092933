import { createAsyncThunk } from '@reduxjs/toolkit'
import get from 'lodash/get'
import { toastr } from 'react-redux-toastr'

import { SnackbarWrapper } from '../../components/toastrOptions/ToastrSnackbar.styled'

import { apiClient } from '../../services/api'
import { getProjectsUsersCount } from '../users/actions'

import { Project, ProjectDto } from './types'

export const getProjects = createAsyncThunk<ProjectDto[], void>(
  'projects/getProjects',
  async (_, { dispatch, rejectWithValue }) => {
    await dispatch(getProjectsUsersCount())

    const [err, res] = await apiClient.get<ProjectDto[]>({
      baseUrl: '/api/hub-management/v1',
      path: '/projects',
    })

    if (err) {
      throw rejectWithValue(err)
    }

    const data: ProjectDto[] = get(res, 'data.data', [])

    return data
  },
)

export const addNewProject = createAsyncThunk<Project, { name: string }>(
  'projects/addProject',
  async ({ name }, { rejectWithValue }) => {
    const [err, res] = await apiClient.post<
      { name: string; sourceSystem: Project['projectSource'] },
      Project
    >({
      baseUrl: '/api/hub-management/v1',
      path: '/projects',
      body: {
        name,
        sourceSystem: 'GStation',
      },
    })
    if (err) {
      throw rejectWithValue(err)
    }

    const data: Project = get(res, 'data.data', {
      name: '',
      projectSource: 'GStation',
      urn: '',
      archived: false,
    })

    toastr.info('', '', {
      icon: <div />,
      component: (
        <SnackbarWrapper>{`Проект "${name}" успешно добавлен`}</SnackbarWrapper>
      ),
    })

    return data
  },
)
