import { useHistory, useParams } from 'react-router'
import { RoundedCross, Tabs, ThreeDotsIcon } from '@gmini/ui-kit'
import { useEffect, useState, MouseEvent, useCallback } from 'react'

import { useContextMenu } from '@gmini/common'

import { Icon } from '@gmini/common/lib/classifier-editor/ContextMenuItem'

import { useAppDispatch, useAppSelector } from '../../store/store'
import { UserDetails } from '../UserDetails/UserDetails'
import { UserRoles } from '../UserRoles/UserRoles'

import { getRoles } from '../../store/roles/actions'

import { User } from '../../store/users/types'
import { getUserById, deleteUserRoles } from '../../store/users/actions'

import {
  BtnContainer,
  EditUserPageWrapper,
  TabTitle,
  Title,
  TitleButton,
  TitleContainer,
} from './EditUserPage.styled'

export const EditUserPage = () => {
  const history = useHistory()
  const { userId, projectUrn, hubId } = useParams<{
    userId: string
    projectUrn: string
    hubId: string
  }>()
  const { list: users } = useAppSelector(state => state.projectUsers)
  const [userState, setUserState] = useState<User | undefined>(
    users.find(u => u.id === userId),
  )
  const [tabIndex, setTabIndex] = useState(0)
  const dispatch = useAppDispatch()

  const getUser = useCallback(async () => {
    const user = await dispatch(getUserById({ id: userId })).unwrap()
    setUserState(user)
  }, [dispatch, userId])

  useEffect(() => {
    if (!userState) {
      getUser()
    }
  }, [getUser, userState])

  useEffect(() => {
    dispatch(getRoles({ projectUrn }))
  }, [dispatch, projectUrn])

  const { ContextMenu, setCtxMenu, ctxMenu } = useContextMenu<{
    item: User
    event: MouseEvent
  }>([
    {
      title: 'Архивировать',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick: props => {},
      icon: Icon.ARCHIVE,
      disabled: () => true,
    },
    {
      title: 'Удалить',
      onClick: async ({ item: { name, userRoles, id } }) => {
        const roleIds = userRoles
          .find(r => r.projectUrn === projectUrn)
          ?.roles.map(r => r.id)
        if (roleIds) {
          await dispatch(
            deleteUserRoles({
              id,
              name,
              toastrText: 'Пользователь удален',
              roleIds,
              projectUrn,
            }),
          )
          history.push(`/hub/${hubId}/project/${projectUrn}`)
        }
      },
      icon: Icon.DELETE,
    },
  ])

  if (!userState) {
    return null
  }
  return (
    <EditUserPageWrapper>
      <ContextMenu />
      <TitleContainer>
        <Title>Редактировать профиль</Title>
        <BtnContainer>
          <TitleButton
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
              setCtxMenu({
                coords: { x: e.clientX, y: e.clientY },
                item: { item: userState, event: e },
              })
            }}
            active={Boolean(ctxMenu.item?.item.id)}
            size='small'
          >
            <ThreeDotsIcon />
          </TitleButton>
          <TitleButton
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
              history.push(`/hub/${hubId}/project/${projectUrn}`)
            }}
            size='small'
          >
            <RoundedCross />
          </TitleButton>
        </BtnContainer>
      </TitleContainer>
      <Tabs
        tabs={[
          {
            title: <TabTitle>Детали</TabTitle>,
            content: <UserDetails user={userState} getUser={getUser} />,
          },
          {
            title: <TabTitle>Роли</TabTitle>,
            content: <UserRoles user={userState} getUser={getUser} />,
          },
        ]}
        activeTabIndex={tabIndex}
        onChangeTab={idx => setTabIndex(idx)}
      />
    </EditUserPageWrapper>
  )
}
