import { useParams } from 'react-router'
import { RoundedCross, Tabs, ThreeDotsIcon } from '@gmini/ui-kit'
import { useState, MouseEvent, Dispatch, SetStateAction } from 'react'

import { useContextMenu } from '@gmini/common'

import { Icon } from '@gmini/common/lib/classifier-editor/ContextMenuItem'

import { useAppDispatch } from '../../store/store'
import { UserDetails } from '../UserDetails/UserDetails'
import { UserRoles } from '../UserRoles/UserRoles'

import { User } from '../../store/users/types'
import {
  deleteUserRoles,
  getAllUsers,
  getProjectUsers,
} from '../../store/users/actions'

import { Drawer } from '../Drawer/Drawer'

import {
  BtnContainer,
  EditUserPageWrapper,
  TabTitle,
  Title,
  TitleButton,
  TitleContainer,
} from './EditUserPage.styled'

type EditUserDrawerProps = {
  userState: User | null
  setUserState: Dispatch<SetStateAction<User | null>>
  noRolesEdit?: boolean
  noContextMenuActions?: boolean
  getUser: (id: string) => Promise<void>
}

export const EditUserDrawer = ({
  userState,
  setUserState,
  noRolesEdit = false,
  noContextMenuActions = false,
  getUser,
}: EditUserDrawerProps) => {
  const { projectUrn } = useParams<{
    projectUrn: string
  }>()

  const [tabIndex, setTabIndex] = useState(0)
  const dispatch = useAppDispatch()

  const { ContextMenu, setCtxMenu, ctxMenu } = useContextMenu<{
    item: User
    event: MouseEvent
  }>([
    {
      title: 'Архивировать',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick: props => {},
      icon: Icon.ARCHIVE,
      disabled: () => true,
    },
    {
      title: 'Удалить',
      onClick: async ({ item: { name, userRoles, id } }) => {
        const roleIds = userRoles
          .find(r => r.projectUrn === projectUrn)
          ?.roles.map(r => r.id)
        if (roleIds) {
          await dispatch(
            deleteUserRoles({
              id,
              name,
              toastrText: 'Пользователь удален',
              roleIds,
              projectUrn,
            }),
          )
          setUserState(null)
          dispatch(getAllUsers())
          dispatch(getProjectUsers({ projectUrn }))
        }
      },
      icon: Icon.DELETE,
    },
  ])

  if (!userState) {
    return null
  }

  const tabs = [
    {
      title: <TabTitle>Детали</TabTitle>,
      content: <UserDetails user={userState} getUser={getUser} />,
    },
  ]

  !noRolesEdit &&
    tabs.push({
      title: <TabTitle>Роли</TabTitle>,
      content: <UserRoles user={userState} getUser={getUser} />,
    })

  return (
    <Drawer>
      <EditUserPageWrapper>
        <ContextMenu />
        <TitleContainer>
          <Title>Редактировать профиль</Title>
          <BtnContainer>
            {!noContextMenuActions ? (
              <TitleButton
                onClick={e => {
                  e.stopPropagation()
                  e.preventDefault()
                  setCtxMenu({
                    coords: { x: e.clientX, y: e.clientY },
                    item: { item: userState, event: e },
                  })
                }}
                active={Boolean(ctxMenu.item?.item.id)}
                size='small'
              >
                <ThreeDotsIcon />
              </TitleButton>
            ) : null}
            <TitleButton
              onClick={e => {
                e.stopPropagation()
                e.preventDefault()
                setUserState(null)
              }}
              size='small'
            >
              <RoundedCross />
            </TitleButton>
          </BtnContainer>
        </TitleContainer>
        <Tabs
          tabs={tabs}
          activeTabIndex={tabIndex}
          onChangeTab={idx => setTabIndex(idx)}
        />
      </EditUserPageWrapper>
    </Drawer>
  )
}
