import { useEffect, useState, MouseEvent } from 'react'
import { useHistory, useParams } from 'react-router'
import { Tooltip, Table, TableColumn, ScrollBar } from '@gmini/ui-kit'
import { useContextMenu } from '@gmini/common'
import { Icon } from '@gmini/common/lib/classifier-editor/ContextMenuItem'

import { useAppDispatch } from '../../store/store'
import { Project } from '../../store/projects/types'
import { getProjects } from '../../store/projects/actions'

import {
  getColumnsFromStorage,
  setColumnsToStorage,
} from '../../helpers/storageHelper'

import { TableWrapper } from './ProjectList.styled'

type ProjectListProps = {
  projects: Project[]
  pending: boolean
}

export const mapSourceTypeDescription: Record<
  Project['projectSource'],
  string
> = {
  Bim360: 'BIM360',
  GStation: 'G-station',
}

type ProjectListRow = Project & {
  status?: 'Активный' | 'Неактивный'
}

const initColumns: TableColumn<ProjectListRow>[] = [
  {
    field: 'name',
    name: 'Проект',
    visible: true,
    cellStyle: { width: 'auto' },
    style: { width: 'auto' },
    renderCell: ({ row }) => (
      <>
        {row.name.length > 25 ? (
          <Tooltip placement='bottom' title={row.name}>
            <>{row.name.substring(0, 25)}...</>
          </Tooltip>
        ) : (
          row.name
        )}
      </>
    ),
  },
  {
    renderCell: ({ row }) => <>{mapSourceTypeDescription[row.projectSource]}</>,
    name: 'Ресурс',
    field: 'projectSource',
    visible: true,
  },
  {
    field: 'status',
    name: 'Статус',
    visible: true,
    style: {
      width: '145px',
    },
    renderCell: ({ row }) => <div>{row.status || 'Активный'}</div>,
  },
  {
    field: 'count',
    name: 'Пользователи',
    visible: true,
    renderCell: ({ row }) => <div>{row.count || 0}</div>,
  },
]

export const ProjectList = ({ projects, pending }: ProjectListProps) => {
  const params = useParams<{ hubId: string }>()
  const hubId = params.hubId || 'default'

  const [columns, setColumns] = useState(
    getColumnsFromStorage('projectListColumnOrder', initColumns),
  )
  const history = useHistory()

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getProjects())
  }, [dispatch])

  useEffect(() => {
    setColumnsToStorage('projectListColumnOrder', columns)
  }, [columns])

  const { ContextMenu, setCtxMenu, ctxMenu } = useContextMenu<{
    item: Project
    event: MouseEvent
  }>([
    {
      title: 'Перейти',
      onClick: props => {
        history.push(`/hub/${hubId}/project/${props.item.urn}/`)
      },
      icon: Icon.SEARCH,
    },
    {
      title: 'Архивировать',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick: props => {},
      icon: Icon.ARCHIVE,
      disabled: () => true,
    },
    {
      title: 'Удалить',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick: props => {},
      icon: Icon.DELETE,
      disabled: () => true,
    },
  ])

  return (
    <TableWrapper>
      {!pending && <ContextMenu />}
      <ScrollBar>
        <Table
          columns={columns}
          rows={projects}
          onRowCtxMenu={(e, item) => {
            e.preventDefault()
            setCtxMenu({
              coords: { x: e.clientX, y: e.clientY },
              item: { item, event: e },
            })
          }}
          pending={pending}
          activeRowKey={ctxMenu.item?.item.urn}
          getRowKey={row => row.urn}
          onChangeColumns={setColumns}
          onClick={(e, item) => {
            history.push(`/hub/${hubId}/project/${item.urn}/`)
          }}
        />
      </ScrollBar>
    </TableWrapper>
  )
}
