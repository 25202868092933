import { Redirect, Route, Switch } from 'react-router-dom'

import { Maintenance, Page404 } from '@gmini/ui-kit'

import { ProjectPage } from '../components/ProjectPage/ProjectPage'
import { HubPage } from '../components/HubPage/HubPage'
import { EditUserPage } from '../components/EditUserPage/EditUserPage'
import { AppSidebar } from '../components/AppSidebar/AppSidebar'
import { UsersPage } from '../components/UsersPage/UsersPage'
import { ModulesListPage } from '../components/ModulesListPage/ModulesListPage'

import { Error403Page } from '../components/Error403Page/Error403Page'

import { getError403 } from '../store/403/403-selectors'

import { useAppSelector } from '../store/store'
import { getMaintenance } from '../store/maintenance/maintenance-selectors'

import { ModulePage } from '../components/ModulePage/ModulePage'

import { RolesPage } from '../components/RolesPage/RolesPage'

import { CompaniesListPage } from '../components/CompaniesListPage/CompaniesListPage'

import { CompanyPage } from '../components/CompanyPage/CompanyPage'

import {
  ModuleScopes,
  checkRolesPermissionsSelector,
} from '../store/users/permissions-selectors'

import { AppContentWrapper } from './App.styled'

export const AppContent = () => {
  const maintenanceEnabled = useAppSelector(getMaintenance)
  const error403Enabled = useAppSelector(getError403)
  const { loading } = useAppSelector(state => state.currentUser)
  const checkRolesPermissions = useAppSelector(checkRolesPermissionsSelector)

  if (loading) {
    return null
  }

  if (maintenanceEnabled) {
    return <Maintenance />
  }

  if (error403Enabled) {
    return <Error403Page />
  }

  return (
    <AppContentWrapper>
      <AppSidebar />
      <Switch>
        <Route
          path='/hub/:hubId/project/:projectUrn/user/:userId'
          component={EditUserPage}
        />
        <Route path='/hub/:hubId/project/:projectUrn' component={ProjectPage} />
        <Route path='/hub/:hubId/users' component={UsersPage} />
        <Route path='/hub/:hubId/modules/:moduleName' component={ModulePage} />
        <Route path='/hub/:hubId/modules' component={ModulesListPage} />
        <Route
          path='/hub/:hubId/companies/:companyId'
          component={CompanyPage}
        />
        <Route path='/hub/:hubId/companies' component={CompaniesListPage} />
        {checkRolesPermissions(ModuleScopes.AC_READ) ? (
          <Route path='/hub/:hubId/roles' component={RolesPage} />
        ) : null}
        <Route path='/hub/:hubId' exact component={HubPage} />
        <Route path='/' exact render={() => <Redirect to='/hub/default' />} />
        <Route component={Page404} />
      </Switch>
    </AppContentWrapper>
  )
}
