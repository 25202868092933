import { useCallback, useState } from 'react'

import { useAppDispatch, useAppSelector } from '../../store/store'
import { getAllUsers } from '../../store/users/actions'
import { User } from '../../store/users/types'
import { AddUserPopup } from '../AddUserPopup/AddUserPopup'
import { EditUserDrawer } from '../EditUserPage/EditUserDrawer'
import { AddUserIcon } from '../icons/AddUserIcon'
import { UsersIcon } from '../icons/UsersIcon'
import { UserList } from '../UserList/UserList'

import {
  AddUserButton,
  ListHeading,
  Title,
  TitleCount,
  UserListWrapper,
} from './UsersPage.styled'

export const UsersPage = () => {
  const dispatch = useAppDispatch()
  const [userDetails, setUserDetails] = useState<User | null>(null)
  const [addUserPopupOpen, setAddUserPopupOpen] = useState(false)

  const getUser = useCallback(
    async (id: string) => {
      const users = await dispatch(getAllUsers()).unwrap()
      const user = users.find(user => user.id === id)
      setUserDetails(user || null)
    },
    [dispatch, setUserDetails],
  )

  const {
    list: users,
    total,
    pending,
  } = useAppSelector(state => state.allUsers)

  return (
    <UserListWrapper>
      <ListHeading>
        <Title>
          <UsersIcon />
          Пользователи <TitleCount>{total}</TitleCount>
        </Title>
        <AddUserButton
          onClick={() => setAddUserPopupOpen(true)}
          size='regular'
          color='primary'
          leftIcon={<AddUserIcon width='16px' height='16px' />}
        >
          <span>Добавить пользователя</span>
        </AddUserButton>
      </ListHeading>
      <AddUserPopup
        open={addUserPopupOpen}
        onClose={() => setAddUserPopupOpen(false)}
        withoutRoles
      />
      <EditUserDrawer
        noRolesEdit
        noContextMenuActions
        getUser={getUser}
        userState={userDetails}
        setUserState={setUserDetails}
      />
      <UserList
        localStoragePrefix='all'
        noExtendedEditOrDelete
        users={users}
        pending={pending}
        setUserDetails={setUserDetails}
      />
    </UserListWrapper>
  )
}
