import { UserType } from './store/users/types'

export const getRoleBGColor = (roleId: UserType | number) => {
  switch (roleId) {
    case UserType.NEW:
    case UserType.ACCOUNT:
      return 'rgba(76, 94, 207, 0.15)'
    case UserType.PROJECT:
      return 'rgba(217, 87, 94, 0.22)'
    case 1:
      return '#82D1CC'
    case 2:
      return '#37B4FB'
    case 3:
      return '#F29D1E'
    case 4:
      return '#529DF5'
    case 5:
      return '#F09000'
    case 6:
      return '#F2C41E'
    case 7:
      return '#CB9B7B'
    case 8:
      return '#37B4FB'
    case 9:
      return '#EC868B'
  }
}

export const getColor = (roleId: UserType | number) => {
  switch (roleId) {
    case UserType.NEW:
    case UserType.ACCOUNT:
      return '#4C5ECF'
    case UserType.PROJECT:
      return '#D9575E'
    default:
      return '#fff'
  }
}
