import { createSlice } from '@reduxjs/toolkit'

import { Module } from '../roles/types'

import { getResources, getRoles, updateRole } from './actions'
import { Role } from './types'

const initialState: {
  list: Role[]
  setById: { [x: string]: Role }
  total: number
  pending: boolean
} = {
  list: [],
  setById: {},
  total: 0,
  pending: false,
}

const roles = createSlice({
  name: 'roles',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getRoles.fulfilled, (state, { payload }) => {
        const setById = payload.reduce(
          (acc: { [x: number]: Role }, r) => ({
            ...acc,
            [r.id]: r,
          }),
          {},
        )
        return {
          list: payload,
          setById,
          total: payload.length,
          pending: false,
        }
      })
      .addCase(getRoles.rejected, (state, _) => ({
        ...state,
        pending: false,
      }))
      .addCase(getRoles.pending, (state, _) => ({ ...state, pending: true }))
      .addCase(updateRole.fulfilled, (state, _) => ({
        ...state,
        pending: false,
      }))
      .addCase(updateRole.rejected, (state, _) => ({
        ...state,
        pending: false,
      }))
      .addCase(updateRole.pending, (state, _) => ({ ...state, pending: true }))
  },
})

export const rolesReducer = roles.reducer

const initialStateResources: {
  list: Module[]
  total: number
  pending: boolean
} = {
  list: [],
  total: 0,
  pending: false,
}

const resources = createSlice({
  name: 'resources',
  initialState: initialStateResources,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getResources.fulfilled, (state, { payload }) => ({
        list: payload,
        total: payload.length,
        pending: false,
      }))
      .addCase(getResources.rejected, (state, _) => ({
        ...state,
        pending: false,
      }))
      .addCase(getResources.pending, (state, _) => ({
        ...state,
        pending: true,
      }))
  },
})

export const resourcesReducer = resources.reducer
