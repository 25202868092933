import { Button, CheckMark } from '@gmini/ui-kit'
import React from 'react'

import { ResetArrowIcon } from '../icons/ResetArrowIcon'

import { useAppSelector } from '../../store/store'

import {
  ButtonSection,
  ChangesInfoSection,
  ConfirmChangesOverlayWrapper,
  StyledButton,
} from './ConfirmChangesOverlay.styled'

type ConfirmChangesOverlayProps = {
  changesCount: number
  onSubmit: () => void
  onReset: () => void
}

export const ConfirmChangesOverlay = ({
  changesCount,
  onSubmit,
  onReset,
}: ConfirmChangesOverlayProps) => {
  const { pending } = useAppSelector(state => state.roles)

  return (
    <ConfirmChangesOverlayWrapper visible={!!changesCount}>
      <ChangesInfoSection>{`Внесено изменений: ${changesCount}`}</ChangesInfoSection>
      <ButtonSection>
        <Button
          color='secondary'
          onClick={onReset}
          leftIcon={<ResetArrowIcon />}
        >
          Отменить
        </Button>
        <StyledButton
          color='primary'
          disabled={pending}
          onClick={onSubmit}
          leftIcon={<CheckMark />}
        >
          Сохранить
        </StyledButton>
      </ButtonSection>
    </ConfirmChangesOverlayWrapper>
  )
}
