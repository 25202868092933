import {
  useEffect,
  useState,
  MouseEvent,
  Dispatch,
  SetStateAction,
} from 'react'
import { useHistory, useParams } from 'react-router'
import { Tooltip, Table, TableColumn, ScrollBar } from '@gmini/ui-kit'
import { useContextMenu } from '@gmini/common'
import { Icon } from '@gmini/common/lib/classifier-editor/ContextMenuItem'

import { useAppDispatch } from '../../store/store'

import { User } from '../../store/users/types'
import {
  getAllUsers,
  getProjectUsers,
  deleteUserRoles,
} from '../../store/users/actions'

import {
  getColumnsFromStorage,
  setColumnsToStorage,
} from '../../helpers/storageHelper'

import { TableWrapper } from './UserList.styled'

type UserListProps = {
  localStoragePrefix: string
  setUserDetails: Dispatch<SetStateAction<User | null>>
  pending: boolean
  users: User[]
  noExtendedEditOrDelete?: boolean
  excludeCompanyField?: boolean
}

type UserListRow = User & {
  email?: string
  companyName?: string
}

const initColumns: TableColumn<UserListRow>[] = [
  {
    field: 'name',
    name: 'Пользователь',
    visible: true,
    cellStyle: { width: 'auto' },
    style: { width: 'auto' },
    renderCell: ({ row }) => (
      <>
        {row.name.length > 25 ? (
          <Tooltip placement='bottom' title={row.name}>
            <>{row.name.substring(0, 25)}...</>
          </Tooltip>
        ) : (
          row.name
        )}
      </>
    ),
  },
  {
    field: 'email',
    name: 'Email',
    visible: true,
    style: {
      width: '145px',
    },
    renderCell: ({ row }) => <div>{row.email}</div>,
  },
  {
    field: 'phone',
    name: 'Телефон',
    visible: true,
    style: {
      width: '145px',
    },
    renderCell: ({ row }) => <div>{row.phone || 'Не задан'}</div>,
  },
]

const companyNameField: TableColumn<UserListRow> = {
  field: 'companyName',
  name: 'Компания',
  visible: true,
  renderCell: ({ row }) => <div>{row.companyName || 'Не задана'}</div>,
}

export const UserList = ({
  setUserDetails,
  pending,
  users,
  noExtendedEditOrDelete = false,
  localStoragePrefix,
  excludeCompanyField = false,
}: UserListProps) => {
  const params = useParams<{ hubId: string; projectUrn: string }>()
  const hubId = params.hubId || 'default'
  const projectUrn = params.projectUrn || 'default'

  const dispatch = useAppDispatch()
  const [columns, setColumns] = useState(
    getColumnsFromStorage(
      `${localStoragePrefix}UserListColumnOrder`,
      excludeCompanyField ? initColumns : [...initColumns, companyNameField],
    ),
  )
  const history = useHistory()

  useEffect(() => {
    setColumnsToStorage(`${localStoragePrefix}UserListColumnOrder`, columns)
  }, [columns, localStoragePrefix])

  const { ContextMenu, setCtxMenu, ctxMenu } = useContextMenu<{
    item: User
    event: MouseEvent
  }>([
    {
      title: 'Редактировать',
      onClick: props => {
        if (noExtendedEditOrDelete) {
          setUserDetails(props.item)
          return
        }
        history.push(
          `/hub/${hubId}/project/${projectUrn}/user/${props.item.id}`,
        )
      },
      icon: Icon.EDIT,
    },
    {
      title: 'Архивировать',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick: props => {},
      icon: Icon.ARCHIVE,
      disabled: () => true,
    },
    {
      title: 'Удалить',
      onClick: async ({ item: { name, userRoles, id } }) => {
        const roleIds = userRoles
          .find(r => r.projectUrn === projectUrn)
          ?.roles.map(r => r.id)
        if (roleIds) {
          await dispatch(
            deleteUserRoles({
              id,
              name,
              toastrText: 'Пользователь удален',
              roleIds,
              projectUrn,
            }),
          )
          dispatch(getProjectUsers({ projectUrn }))
          dispatch(getAllUsers())
        }
      },
      icon: Icon.DELETE,
      disabled: () => noExtendedEditOrDelete,
    },
  ])

  return (
    <TableWrapper>
      {!pending && <ContextMenu />}
      <ScrollBar>
        <Table
          columns={columns}
          rows={users}
          onRowCtxMenu={(e, item) => {
            e.preventDefault()
            setCtxMenu({
              coords: { x: e.clientX, y: e.clientY },
              item: { item, event: e },
            })
          }}
          pending={pending}
          activeRowKey={ctxMenu.item?.item.id}
          getRowKey={row => row.id}
          onChangeColumns={setColumns}
          onClick={(e, item) => {
            setUserDetails(item)
          }}
        />
      </ScrollBar>
    </TableWrapper>
  )
}
