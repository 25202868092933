import { Select } from '@gmini/ui-kit'
import { useCallback, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { Company } from '../../store/companies/types'

import { useAppDispatch, useAppSelector } from '../../store/store'
import { updateUser } from '../../store/users/actions'

import { User } from '../../store/users/types'

import {
  FieldError,
  FieldLabel,
  TextField,
} from '../AddProjectPopup/AddProjectPopup.styled'
import { ActionButton, ActionButtons } from '../UserRoles/UserRoles.styled'

import { Form, FieldContainer, UserDetailsWrapper } from './UserDetails.styled'

const requiredErrorMessage = 'Это поле является обязательным'

type UserDetailsProps = {
  user: User
  getUser: (id: string) => Promise<void>
}

export const UserDetails = ({ user, getUser }: UserDetailsProps) => {
  const { list: companies } = useAppSelector(state => state.companies)

  const dispatch = useAppDispatch()
  const defaultValues = {
    name: user.name,
    email: user.email,
    phone: user.phone || '',
    company: companies.find(c => c.id === user.companyId) || null,
  }
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    getValues,
  } = useForm({ mode: 'onChange', defaultValues })

  const onSubmit = useCallback(
    async (data: typeof defaultValues) => {
      const { email, name, phone, company } = data
      if (!email) {
        return
      }
      await dispatch(
        updateUser({
          id: user.id,
          email,
          name,
          phone,
          companyId: company?.id || null,
        }),
      )
      getUser(user.id)
    },
    [user.id, dispatch, getUser],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => reset(defaultValues), [user])

  if (!user) {
    return null
  }
  return (
    <UserDetailsWrapper>
      <Form>
        <FieldContainer>
          <FieldLabel required>ФИО</FieldLabel>
          <Controller
            name='name'
            control={control}
            rules={{
              required: { message: requiredErrorMessage, value: true },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                error={Boolean(errors.name)}
                //оставил, чтобы не забыть проп, когда поля можно будет редактировать
                // clearable
                disabled
              />
            )}
          />
          <FieldError hidden={!errors.name}>
            {errors.name && 'message' in errors.name
              ? errors.name.message
              : null}
          </FieldError>
        </FieldContainer>
        <FieldContainer>
          <FieldLabel required>Email</FieldLabel>
          <Controller
            name='email'
            control={control}
            rules={{
              required: { message: requiredErrorMessage, value: true },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                error={Boolean(errors.email)}
                //оставил, чтобы не забыть проп, когда поля можно будет редактировать
                // clearable
                disabled
              />
            )}
          />
          <FieldError hidden={!errors.email}>
            {errors.email && 'message' in errors.email
              ? errors.email.message
              : null}
          </FieldError>
        </FieldContainer>
        <FieldContainer>
          <FieldLabel>Телефон</FieldLabel>
          <Controller
            name='phone'
            control={control}
            rules={{
              pattern: {
                value:
                  // eslint-disable-next-line prefer-named-capture-group
                  /(^\+?\d{1,3})[ -]?(\d{3})[ -]?(\d{3})[ -]?(\d{2})[ -]?(\d{2})[ -]?$/mu,
                message: 'Неверный формат',
              },
            }}
            render={({ field }) => (
              <TextField {...field} error={Boolean(errors.phone)} clearable />
            )}
          />
          <FieldError hidden={!errors.phone}>
            {errors.phone && 'message' in errors.phone
              ? errors.phone.message
              : null}
          </FieldError>
        </FieldContainer>
        <FieldContainer>
          <FieldLabel>Компания</FieldLabel>
          <Controller
            name='company'
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={companies}
                getOptionLabel={(option: Company) => option.companyName}
                emptyOptionListMessage='Нет доступных совпадений'
                placeholder='Укажите компанию'
                error={Boolean(errors.company)}
              />
            )}
          />
          <FieldError hidden={!errors.company}>
            {errors.company && 'message' in errors.company
              ? errors.company.message
              : null}
          </FieldError>
        </FieldContainer>
      </Form>
      <ActionButtons>
        <ActionButton onClick={() => reset(defaultValues)} color='secondary'>
          Отменить
        </ActionButton>
        <ActionButton
          color='primary'
          disabled={!getValues().email.trim()}
          onClick={handleSubmit(onSubmit)}
        >
          Применить
        </ActionButton>
      </ActionButtons>
    </UserDetailsWrapper>
  )
}
