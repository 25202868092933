import { createAsyncThunk } from '@reduxjs/toolkit'
import get from 'lodash/get'

import { apiClient } from '../../services/api'

import { ModuleUsersCount } from './types'

export const getModuleUsersCount = createAsyncThunk<ModuleUsersCount[], void>(
  'projects/getModuleUsersCount',
  async (_, { rejectWithValue }) => {
    const [err, res] = await apiClient.get({
      path: '/auth/admin/users/count-by-modules',
    })
    if (err) {
      throw rejectWithValue(err)
    }

    const data: ModuleUsersCount[] = get(res, 'data.data', [])

    return data
  },
)
