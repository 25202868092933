import { TableColumn, TableDataItem } from '@gmini/ui-kit'

type ColumnSettings = {
  field: string
  visible: boolean
}

export function getColumnsFromStorage<T extends TableDataItem>(
  storageKey: string,
  columns: TableColumn<T>[],
): TableColumn<T>[] {
  const data = localStorage.getItem(storageKey)
  if (typeof data === 'string') {
    try {
      const parsedData = JSON.parse(data) as ColumnSettings[]

      return columns
        .slice()
        .sort((a, b) => {
          const aIdx = parsedData.findIndex(s => s.field === a.field)
          const bIdx = parsedData.findIndex(s => s.field === b.field)

          // В случае если в localStorage не было настройки колонки (например: в коде добавили новую)
          if (aIdx < 0 || bIdx < 0) {
            return 0
          }

          return aIdx - bIdx
        })
        .map(col => ({
          ...col,
          visible: parsedData.some(s => s.field === col.field && s.visible),
        }))
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err)
    }
  }
  return columns
}

export function setColumnsToStorage<T extends TableDataItem>(
  storageKey: string,
  next: TableColumn<T>[],
) {
  localStorage.setItem(
    storageKey,
    JSON.stringify(next.map(({ field, visible }) => ({ field, visible }))),
  )
}
