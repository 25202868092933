import { useEffect, useState, MouseEvent } from 'react'
import { useHistory, useParams } from 'react-router'
import { Tooltip, Table, TableColumn, ScrollBar } from '@gmini/ui-kit'
import { useContextMenu } from '@gmini/common'
import { Icon } from '@gmini/common/lib/classifier-editor/ContextMenuItem'

import { useAppDispatch, useAppSelector } from '../../store/store'
import { getModuleUsersCount } from '../../store/modules/actions'
import { ModuleUsersCount } from '../../store/modules/types'

import { DashboardIcon } from '../icons/DashboardIcon'

import {
  getColumnsFromStorage,
  setColumnsToStorage,
} from '../../helpers/storageHelper'

import {
  ListHeading,
  ModulesListWrapper,
  TableWrapper,
  Title,
  TitleCount,
} from './ModulesListPage.styled'

type ModuleListRow = ModuleUsersCount & {
  name?: string
}

const initColumns: TableColumn<ModuleListRow>[] = [
  {
    field: 'name',
    name: 'Модуль',
    visible: true,
    cellStyle: { width: 'auto' },
    style: { width: 'auto' },
    renderCell: ({ row }) => (
      <>
        {row.name && row.name?.length > 25 ? (
          <Tooltip placement='bottom' title={row.name || ''}>
            <>{row.name?.substring(0, 25)}...</>
          </Tooltip>
        ) : (
          row.name || row.module
        )}
      </>
    ),
  },
  {
    field: 'count',
    name: 'Пользователи',
    visible: true,
  },
]

export const ModulesListPage = () => {
  const params = useParams<{ hubId: string }>()
  const hubId = params.hubId || 'default'

  const [columns, setColumns] = useState(
    getColumnsFromStorage('modulesListColumnOrder', initColumns),
  )
  const history = useHistory()

  const {
    list: modules,
    pending,
    total,
  } = useAppSelector(state => state.modules)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getModuleUsersCount())
  }, [dispatch])

  useEffect(() => {
    setColumnsToStorage('modulesListColumnOrder', columns)
  }, [columns])

  const { ContextMenu, setCtxMenu, ctxMenu } = useContextMenu<{
    item: ModuleUsersCount
    event: MouseEvent
  }>([
    {
      title: 'Перейти',
      onClick: props => {
        history.push(`/hub/${hubId}/modules/${props.item.module}/`)
      },
      icon: Icon.SEARCH,
    },
    {
      title: 'Архивировать',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick: props => {},
      icon: Icon.ARCHIVE,
      disabled: () => true,
    },
    {
      title: 'Удалить',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick: props => {},
      icon: Icon.DELETE,
      disabled: () => true,
    },
  ])

  return (
    <ModulesListWrapper>
      <ListHeading>
        <Title>
          <DashboardIcon />
          Модули <TitleCount>{total}</TitleCount>
        </Title>
      </ListHeading>
      <TableWrapper>
        {!pending && <ContextMenu />}
        <ScrollBar>
          <Table
            columns={columns}
            rows={modules}
            onRowCtxMenu={(e, item) => {
              e.preventDefault()
              setCtxMenu({
                coords: { x: e.clientX, y: e.clientY },
                item: { item, event: e },
              })
            }}
            pending={pending}
            activeRowKey={ctxMenu.item?.item.module}
            getRowKey={row => row.module}
            onChangeColumns={setColumns}
            onClick={(e, item) => {
              history.push(`/hub/${hubId}/modules/${item.module}/`)
            }}
          />
        </ScrollBar>
      </TableWrapper>
    </ModulesListWrapper>
  )
}
