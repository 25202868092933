import { SvgComponentType } from '@gmini/ui-kit'

export const InfoIconRed = ({
  color = '#EC868B',
  ...other
}: SvgComponentType) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...other}
  >
    <g clipPath='url(#clip0_992_280074)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM13 9V7H11V9H13ZM13 17V11H11V17H13Z'
        fill={color}
      />
    </g>
    <defs>
      <clipPath id='clip0_992_280074'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
