import { createSlice } from '@reduxjs/toolkit'

import { getModuleUsersCount } from './actions'
import { modulesNames, ModuleUsersCount } from './types'

const modulesInitialState: {
  list: ModuleUsersCount[]
  total: number
  pending: boolean
} = {
  list: [],
  total: 0,
  pending: false,
}

const modules = createSlice({
  name: 'modules',
  initialState: modulesInitialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getModuleUsersCount.fulfilled, (state, { payload }) => ({
        list: payload.map(m => ({
          ...m,
          name: modulesNames[m.module],
        })),
        total: payload.length,
        pending: false,
      }))
      .addCase(getModuleUsersCount.rejected, (state, _) => ({
        ...state,
        pending: false,
      }))
      .addCase(getModuleUsersCount.pending, (state, _) => ({
        ...state,
        pending: true,
      }))
  },
})

export const modulesReducer = modules.reducer
