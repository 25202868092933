import { createSlice } from '@reduxjs/toolkit'

import { UserInfo } from '@gmini/common'

import { getCompanies } from '../companies/actions'

import { envName } from '../../config'

import {
  getCurrentUser,
  getProjectUsers,
  getAllUsers,
  getModuleUsers,
  getCompanyUsers,
} from './actions'
import { User } from './types'

const projectUsersInitialState: {
  list: User[]
  total: number
  pending: boolean
  companiesNamesMap: { [x: string]: string }
} = {
  list: [],
  total: 0,
  pending: false,
  companiesNamesMap: {},
}

const projectUsers = createSlice({
  name: 'projectUsers',
  initialState: projectUsersInitialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getProjectUsers.fulfilled, (state, { payload }) => {
        const fusedList = payload.map(u => ({
          ...u,
          companyName: state.companiesNamesMap[u.companyId || ''],
        }))

        return {
          ...state,
          list: fusedList,
          total: payload.length,
          pending: false,
        }
      })
      .addCase(getProjectUsers.rejected, (state, _) => ({
        ...state,
        pending: false,
      }))
      .addCase(getProjectUsers.pending, (state, _) => ({
        ...state,
        pending: true,
      }))
      .addCase(getCompanies.fulfilled, (state, { payload }) => {
        const companiesNamesMap: { [x: string]: string } = payload.reduce(
          (acc, c) => ({
            ...acc,
            [c.id]: c.companyName,
          }),
          {},
        )
        const newUsers = state.list.map(u => ({
          ...u,
          companyName: companiesNamesMap[u.companyId || ''],
        }))
        return {
          ...state,
          companiesNamesMap,
          list: newUsers,
        }
      })
  },
})

export const projectUsersReducer = projectUsers.reducer

const companyUsersInitialState: {
  list: User[]
  total: number
  pending: boolean
} = {
  list: [],
  total: 0,
  pending: false,
}

const companyUsers = createSlice({
  name: 'companyUsers',
  initialState: companyUsersInitialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getCompanyUsers.fulfilled, (state, { payload }) => ({
        list: payload,
        total: payload.length,
        pending: false,
      }))
      .addCase(getCompanyUsers.rejected, (state, _) => ({
        ...state,
        pending: false,
      }))
      .addCase(getCompanyUsers.pending, (state, _) => ({
        ...state,
        pending: true,
      }))
  },
})

export const companyUsersReducer = companyUsers.reducer

const moduleUsersInitialState: {
  list: User[]
  total: number
  pending: boolean
  companiesNamesMap: { [x: string]: string }
} = {
  list: [],
  total: 0,
  pending: false,
  companiesNamesMap: {},
}

const moduleUsers = createSlice({
  name: 'moduleUsers',
  initialState: moduleUsersInitialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getModuleUsers.fulfilled, (state, { payload }) => {
        const fusedList = payload.map(u => ({
          ...u,
          companyName: state.companiesNamesMap[u.companyId || ''],
        }))

        return {
          ...state,
          list: fusedList,
          total: payload.length,
          pending: false,
        }
      })
      .addCase(getModuleUsers.rejected, (state, _) => ({
        ...state,
        pending: false,
      }))
      .addCase(getModuleUsers.pending, (state, _) => ({
        ...state,
        pending: true,
      }))
      .addCase(getCompanies.fulfilled, (state, { payload }) => {
        const companiesNamesMap: { [x: string]: string } = payload.reduce(
          (acc, c) => ({
            ...acc,
            [c.id]: c.companyName,
          }),
          {},
        )
        const newUsers = state.list.map(u => ({
          ...u,
          companyName: companiesNamesMap[u.companyId || ''],
        }))
        return {
          ...state,
          companiesNamesMap,
          list: newUsers,
        }
      })
  },
})

export const moduleUsersReducer = moduleUsers.reducer

const allUsersInitialState: {
  list: User[]
  total: number
  pending: boolean
  companiesNamesMap: { [x: string]: string }
} = {
  list: [],
  total: 0,
  pending: false,
  companiesNamesMap: {},
}

const allUsers = createSlice({
  name: 'allUsers',
  initialState: allUsersInitialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllUsers.fulfilled, (state, { payload }) => {
        const fusedList = payload.map(u => ({
          ...u,
          companyName: state.companiesNamesMap[u.companyId || ''],
        }))

        return {
          ...state,
          list: fusedList,
          total: payload.length,
          pending: false,
        }
      })
      .addCase(getAllUsers.rejected, (state, _) => ({
        ...state,
        pending: false,
      }))
      .addCase(getAllUsers.pending, (state, _) => ({
        ...state,
        pending: true,
      }))
      .addCase(getCompanies.fulfilled, (state, { payload }) => {
        const companiesNamesMap: { [x: string]: string } = payload.reduce(
          (acc, c) => ({
            ...acc,
            [c.id]: c.companyName,
          }),
          {},
        )
        const newUsers = state.list.map(u => ({
          ...u,
          companyName: companiesNamesMap[u.companyId || ''],
        }))
        return {
          ...state,
          companiesNamesMap,
          list: newUsers,
        }
      })
  },
})

export const allUsersReducer = allUsers.reducer

const currentUserInitialState: UserInfo & {
  loading: boolean
  hubPermissionsMap: { [x: string]: { [x: string]: boolean } }
} = {
  id: '',
  name: '',
  email: '',
  hubPermissionsMap: {},
  loading: true,
}

const currentUser = createSlice({
  name: 'currentUser',
  initialState: currentUserInitialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getCurrentUser.fulfilled, (state, { payload }) => {
      const hubPermissionsMap = Object.entries(
        payload.hubPermissions?.[`${envName}`] || {},
      ).reduce(
        (acc, [key, scopes]) => ({
          ...acc,
          [key]: scopes.reduce((acc, scope) => ({ ...acc, [scope]: true }), {}),
        }),
        {},
      )
      return {
        ...payload,
        hubPermissionsMap,
        loading: false,
      }
    })
    builder.addCase(getCurrentUser.rejected, (state, { payload }) => ({
      ...state,
      loading: false,
    }))
    builder.addCase(getCurrentUser.pending, (state, { payload }) => ({
      ...state,
      loading: true,
    }))
  },
})

export const currentUserReducer = currentUser.reducer
