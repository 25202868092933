import { IconButton } from '@gmini/ui-kit'
import styled from 'styled-components'

export const EditUserPageWrapper = styled.div`
  width: 100%;
  padding: 24px;
  padding-bottom: 0;
  height: inherit;
  display: flex;
  flex-direction: column;

  & > :last-child {
    flex: 1;
    padding-bottom: 0;
  }
`

export const Title = styled.div`
  font-size: 20px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #000335;
`
export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 13px;
`

export const TabTitle = styled.div`
  margin: 6px 10px;
`

export const BtnContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 28px;
`

export const TitleButton = styled(IconButton)`
  padding: 0;
  margin-left: 'auto';
  margin-right: '6px';

  & svg {
    width: 20px;
    height: 20px;
    & path {
      fill: #353b60;
    }
  }
`
