import { createSlice } from '@reduxjs/toolkit'

import { getProjectsUsersCount } from '../users/actions'

import { getProjects } from './actions'
import { Project } from './types'

const initialState: {
  list: Project[]
  pending: boolean
  setByUrn: { [x: string]: Project }
  countSet: { [x: string]: number }
  total: number
} = {
  list: [],
  setByUrn: {},
  total: 0,
  pending: false,
  countSet: {},
}

const projects = createSlice({
  name: 'projects',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getProjects.fulfilled, (state, { payload }) => {
        const setByUrn = payload.reduce(
          (acc: { [x: string]: Project }, { project }) => ({
            ...acc,
            [project.urn]: { ...project, count: state.countSet[project.urn] },
          }),
          {},
        )
        const fusedList = payload.map(({ project }) => ({
          ...project,
          count: state.countSet[project.urn],
        }))

        return {
          ...state,
          list: fusedList,
          setByUrn,
          total: payload.length || 0,
          pending: false,
        }
      })
      .addCase(getProjects.rejected, (state, _) => ({
        ...state,
        pending: false,
      }))
      .addCase(getProjects.pending, (state, _) => ({ ...state, pending: true }))
      .addCase(getProjectsUsersCount.fulfilled, (state, { payload }) => {
        const countSet = payload.reduce(
          (acc: { [x: string]: number }, { projectUrn, count }) => ({
            ...acc,
            [projectUrn]: count,
          }),
          {},
        )
        return {
          ...state,
          countSet,
        }
      })
  },
})

export const projectsReducer = projects.reducer
