import { combineReducers } from 'redux'
import { reducer as toastrReducer } from 'react-redux-toastr'

import {
  currentUserReducer,
  projectUsersReducer,
  allUsersReducer,
  moduleUsersReducer,
  companyUsersReducer,
} from './users/users-slice'
import { error403 } from './403/403.slice'
import { maintenance } from './maintenance/maintenance.slice'
import { modulesReducer } from './modules/modules-slice'
import { projectsReducer } from './projects/projects-slice'
import { resourcesReducer, rolesReducer } from './roles/roles-slice'
import { companiesReducer } from './companies/companies-slice'

export const rootReducer = combineReducers({
  currentUser: currentUserReducer,
  projectUsers: projectUsersReducer,
  companyUsers: companyUsersReducer,
  moduleUsers: moduleUsersReducer,
  allUsers: allUsersReducer,
  projects: projectsReducer,
  companies: companiesReducer,
  toastr: toastrReducer,
  roles: rolesReducer,
  resources: resourcesReducer,
  modules: modulesReducer,
  maintenance,
  error403,
})
