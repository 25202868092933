import { createSelector } from 'reselect'

import { RootState } from '../store'

enum ModuleResources {
  ADMIN_ROLE = 'ADMIN_ROLE',
}

export enum ModuleScopes {
  AC_READ = 'AC_READ',
  AC_EDIT = 'AC_EDIT',
  AC_CREATE = 'AC_CREATE',
  AC_DELETE = 'AC_DELETE',
}

export const getHubPermissionsMapSelector = createSelector(
  (state: RootState) => state.currentUser,
  (currentUser: RootState['currentUser']) => currentUser.hubPermissionsMap,
)

export const checkRolesPermissionsSelector = createSelector(
  getHubPermissionsMapSelector,
  hubPermissionsMap => (scope: ModuleScopes) =>
    hubPermissionsMap?.[ModuleResources.ADMIN_ROLE]?.[scope],
)
