import styled from 'styled-components'

export const Form = styled.div`
  display: flex;
  width: 100%;
  gap: 12px;
  flex-wrap: wrap;
`
export const FieldContainer = styled.div`
  width: 48%;
`
export const UserDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`
