import { UserInfo } from '@gmini/common'

import { PermissionsDto } from '../roles/types'

export type RolesForUser = {
  roles: {
    id: number
    title: string
  }[]
  projectUrn: string
}

export type User = {
  id: string
  name: string
  email: string
  phone: string | null
  companyId: number | null
  userRoles: RolesForUser[]
}

export type UserInfoWithPermissions = UserInfo & {
  permissions: PermissionsDto[]
  roles: RolesForUser[]
}

export type UserWithHubPermissions = UserInfo & {
  hubPermissions: { [x: string]: { [x: string]: string[] } }
}

export enum UserType {
  NEW = 'Новый пользователь',
  ACCOUNT = 'Пользователь аккаунта',
  PROJECT = 'Пользователь проекта',
}
