import { ActionPopupWrapper } from '@gmini/ui-kit'

import { useForm, Controller } from 'react-hook-form'

import { Dispatch, SetStateAction } from 'react'

import { useAppDispatch } from '../../store/store'

import { createRole, getRoles } from '../../store/roles/actions'
import { Role } from '../../store/roles/types'

import {
  EmptyContainer,
  FieldContainer,
  FieldError,
  FieldLabel,
  Form,
  StyledTextArea,
  TextField,
} from './AddRolePopup.styled'

type AddRolePopupProps = {
  open: boolean
  onClose: () => void
  setSelectedRole: Dispatch<SetStateAction<Role | null>>
}
const requiredErrorMessage = 'Это поле является обязательным'

const defaultValues = {
  title: '',
  description: '',
}

export const AddRolePopup = ({
  open,
  onClose,
  setSelectedRole,
}: AddRolePopupProps) => {
  const {
    handleSubmit,
    control,
    formState: { dirtyFields, errors },
    reset,
  } = useForm({ mode: 'onChange', defaultValues })
  const dispatch = useAppDispatch()

  const onSubmit = async (data: typeof defaultValues) => {
    const { title: titleInit, description: descriptionInit } = data
    const title = titleInit.trim()
    const description = descriptionInit.trim()
    const newRole = await dispatch(createRole({ title, description })).unwrap()
    setSelectedRole(newRole)
    dispatch(getRoles({ projectUrn: '' }))
    onClose()
  }

  return (
    <ActionPopupWrapper
      title='Добавить роль'
      submitButtonTitle='Добавить'
      pending={!dirtyFields.title}
      open={open}
      width='600px'
      onClose={() => {
        reset()
        onClose()
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      {({ existScroll }) => (
        <Form>
          <FieldContainer>
            <FieldLabel required>Наименование</FieldLabel>
            <Controller
              name='title'
              control={control}
              rules={{
                required: { message: requiredErrorMessage, value: true },
              }}
              render={({ field }) => (
                <TextField {...field} error={Boolean(errors.title)} clearable />
              )}
            />
            <FieldError hidden={!errors.title}>
              {errors.title && 'message' in errors.title
                ? errors.title.message
                : null}
            </FieldError>
          </FieldContainer>
          <FieldContainer>
            <FieldLabel>Описание</FieldLabel>
            <Controller
              name='description'
              control={control}
              render={({ field }) => (
                <StyledTextArea
                  {...field}
                  error={Boolean(errors.description)}
                  placeholder='Краткое описание роли'
                />
              )}
            />
            <FieldError hidden={!errors.description}>
              {errors.description && 'message' in errors.description
                ? errors.description.message
                : null}
            </FieldError>
          </FieldContainer>
          {existScroll && <EmptyContainer />}
        </Form>
      )}
    </ActionPopupWrapper>
  )
}
