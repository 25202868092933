import styled, { css } from 'styled-components'

export const HeaderContainer = styled.div`
  position: relative;
  z-index: 2;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.04),
    rgba(0, 0, 0, 0.02) 25%,
    transparent 60%
  );
  //TODO: для сокрытия сепаратора, пока слева от юзеринфо ничего нет
  & > div > :nth-child(2) {
    display: none;
  }
`

type BrandContainerProps = {
  active?: boolean
}

export const BrandContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 50px;
  margin-right: auto;
  min-height: 100%;
  background: inherit;
  color: #00003d;
  font-size: 12px;
  font-family: Roboto, sans-serif;
  letter-spacing: 0.1px;
  font-weight: 700;
  line-height: 21px;
  text-transform: uppercase;
  text-decoration: none;
  cursor: default;
  transition: 0.2s ease-out background-color;
  padding: 8px 18px;
  border-radius: 4px;

  &:hover {
    background: #e5e7f1;
  }

  ${(props: BrandContainerProps) =>
    props.active &&
    css`
      background: #e5e7f1;
    `}
`

export const BrandTitle = styled.div`
  margin-right: 8px;
`
