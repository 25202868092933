import { useEffect, useState, MouseEvent } from 'react'
import { useHistory, useParams } from 'react-router'
import {
  Tooltip,
  Table,
  TableColumn,
  ScrollBar,
  PlusCircle,
} from '@gmini/ui-kit'
import { useContextMenu } from '@gmini/common'
import { Icon } from '@gmini/common/lib/classifier-editor/ContextMenuItem'

import { useAppDispatch, useAppSelector } from '../../store/store'
import { Company } from '../../store/companies/types'
import { getCompanies } from '../../store/companies/actions'

import { AddOrEditCompanyPopup } from '../AddOrEditCompanyPopup/AddOrEditCompanyPopup'

import { CompaniesIcon } from '../icons/CompaniesIcon'

import { CompanyLogoPlaceholder } from '../CompanyLogoPlaceholder/CompanyLogoPlaceholder'

import {
  getColumnsFromStorage,
  setColumnsToStorage,
} from '../../helpers/storageHelper'

import {
  AddCompanyButton,
  ButtonContainer,
  CompanyListWrapper,
  ListHeading,
  TableWrapper,
  Title,
  TitleCount,
} from './CompaniesListPage.styled'

type CompaniesListRow = Company

const initColumns: TableColumn<CompaniesListRow>[] = [
  {
    field: 'photo',
    name: 'Логотип',
    visible: true,
    renderCell: ({ row }) => (
      <CompanyLogoPlaceholder photo={row.photo} size='medium' />
    ),
  },
  {
    field: 'companyName',
    name: 'Компания',
    visible: true,
    cellStyle: { width: 'auto' },
    style: { width: 'auto' },
    renderCell: ({ row }) => (
      <>
        {row.companyName.length > 25 ? (
          <Tooltip placement='bottom' title={row.companyName}>
            <>{row.companyName.substring(0, 25)}...</>
          </Tooltip>
        ) : (
          row.companyName
        )}
      </>
    ),
  },
  {
    field: 'count',
    name: 'Пользователи',
    visible: true,
    renderCell: ({ row }) => <div>{row.count || 0}</div>,
  },
]

export const CompaniesListPage = () => {
  const [addCompanyPopupOpen, setAddCompanyPopupOpen] = useState(false)
  const params = useParams<{ hubId: string }>()
  const hubId = params.hubId || 'default'

  const {
    list: companies,
    pending,
    total,
  } = useAppSelector(state => state.companies)
  const [columns, setColumns] = useState(
    getColumnsFromStorage('companiesListColumnOrder', initColumns),
  )
  const history = useHistory()

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getCompanies())
  }, [dispatch])

  useEffect(() => {
    setColumnsToStorage('companiesListColumnOrder', columns)
  }, [columns])

  const { ContextMenu, setCtxMenu, ctxMenu } = useContextMenu<{
    item: Company
    event: MouseEvent
  }>([
    {
      title: 'Перейти',
      onClick: props => {
        history.push(`/hub/${hubId}/companies/${props.item.id}/`)
      },
      icon: Icon.SEARCH,
    },
    {
      title: 'Архивировать',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick: props => {},
      icon: Icon.ARCHIVE,
      disabled: () => true,
    },
    {
      title: 'Удалить',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick: props => {},
      icon: Icon.DELETE,
      disabled: () => true,
    },
  ])

  return (
    <CompanyListWrapper>
      <ListHeading>
        <Title>
          <CompaniesIcon />
          Компании <TitleCount>{total}</TitleCount>
        </Title>
        <ButtonContainer>
          <AddCompanyButton
            onClick={() => setAddCompanyPopupOpen(true)}
            size='regular'
            color='primary'
            leftIcon={<PlusCircle width='16px' height='16px' />}
          >
            <span>Добавить компанию</span>
          </AddCompanyButton>
        </ButtonContainer>
      </ListHeading>
      <AddOrEditCompanyPopup
        open={addCompanyPopupOpen}
        onClose={() => setAddCompanyPopupOpen(false)}
      />
      <TableWrapper>
        {!pending && <ContextMenu />}
        <ScrollBar>
          <Table
            columns={columns}
            rows={companies}
            onRowCtxMenu={(e, item) => {
              e.preventDefault()
              setCtxMenu({
                coords: { x: e.clientX, y: e.clientY },
                item: { item, event: e },
              })
            }}
            pending={pending}
            activeRowKey={ctxMenu.item?.item.id}
            getRowKey={row => row.id}
            onChangeColumns={setColumns}
            onClick={(e, item) => {
              history.push(`/hub/${hubId}/companies/${item.id}/`)
            }}
          />
        </ScrollBar>
      </TableWrapper>
    </CompanyListWrapper>
  )
}
