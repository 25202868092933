import { Button, IconButton } from '@gmini/ui-kit'
import styled from 'styled-components'

export const UserListWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const ButtonContainer = styled.div``

export const AddUserButton = styled(Button)`
  margin-left: auto;
  background: #4c5ecf;
  color: #fff;
  & path {
    fill: #fff;
  }
  &:hover,
  &:active {
    background: #2e387c;
  }
`
export const BackButton = styled(IconButton)`
  width: 24px;
  height: 24px;
  path {
    fill: #4c5ecf;
  }
`
export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #353b60;
`
export const TitleCount = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: rgba(53, 59, 96, 0.5);
`
export const ListHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 26px 24px;
`
